.tech-wrapper {
	height: auto;
	text-align: center;
	padding: 0px 20px 50px 20px;
	background-color: #fff;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	font-size: large;
}

img {
	display: block;
	height: 100px;
	max-width: 125px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 5px;
	padding-bottom: 5px;
  }

  .paper {
	  background-color: #ececec !important;
	  /* padding: 10px; */
	  cursor: pointer !important;
  }
  .papers {
	background-color: #ececec !important;
}
  .stack {
	  background-color: transparent;
	  padding: 10px;
  }
  .dialog {
	  margin: 0px 15px;
	  font-size: 1.5em;
  }