.second-hightlight-wrapper {
	height: 800px;
	background-position: bottom;
	background-repeat: no-repeat;
  	background-size: initial;
  	background-color: #fbfbfd;
	text-align: center;
	padding: 65px 20px;
	color: #000;
	margin-bottom: 10px;	
	font-size: 1.8em;
}
.heroimage {
	object-fit: cover;
	border-radius: 25px;
	height: 500px;
	width: 90%;
}

@media (min-width: 768px) {
	.heroimage {
        width: 660px
	}
}

@media (min-width: 992px) {
	.heroimage {
        width: 800px;
	}
}