.detail-wrapper {
	background-color: white;
    width: 100%;
	text-align: center;
	color: #000;
    margin: auto;
	padding-bottom: 20px;
}
.appstore {
	height: 50px;
	width: 100px;
}
.image {
    width: auto;
    height: 500px;
	margin-bottom: 30px;
}

.title {
	margin: auto;
}

.detail-wrapper {
	padding: 10px 10px;
}

.tech {
	width: 100px;
	height: 100px;
	float: left;
}

blockquote p {
    padding: 15px;
    background: #eee;
    border-radius: 5px;
}
.about {
	margin-top: 30px;
	text-align: left;
	font-size: 1em;
}
.list {
	padding-top: 20px;
	padding-left: 50px;
}
.grid {
	font-size: medium;
}
@media (min-width: 768px) {
	.detail-wrapper {
        width: 760px
	}
}

@media (min-width: 992px) {
	.detail-wrapper {
        width: 900px;
	}
}