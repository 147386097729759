.featured-app-wrapper {
	background-color: #e6e6e6;
	background-image: url("../../../images/projects/sparked/campus.png"), url("../../../images/projects/sparked/courses.png"), url("../../../images/projects/sparked/settings.png");
	height: 800px;
	background-position: center 250px, left 250px, right 250px;
	background-repeat: no-repeat;
  	background-size: contain;
	background-origin: content-box;
	text-align: center;
	padding: 65px 50px;
	color: #fff;
}

@media (min-width: 768px) {
	.featured-app-wrapper {
		padding: 65px 100px;
	}
}

@media (min-width: 992px) {
	.featured-app-wrapper {
		padding: 65px 200px;
	}
}