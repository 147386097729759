.projects-wrapper .left-side-wrapper .left-side-container{
	background-image:  url("../../../images/projects/useful/widget.png"); 
	background-color: #1B98F5;
	padding-top: 35px;
	margin: 10px 0 10px 0;
	background-position: center 150px;
	background-repeat: no-repeat;
  	background-size: contain, cover;
	background-origin: content-box;	
}
.projects-wrapper .right-side-wrapper .right-side-container{
	background-image:  url("../../../images/projects/onscene/cluster.png"); 
	background-color: #165ca9;
	padding-top: 35px;
	margin: 0 0 10px 0;
	background-position: center 150px;
	background-repeat: no-repeat;
  	background-size: contain, cover;
	background-origin: content-box;
}

@media (min-width: 768px) {
.projects-wrapper .left-side-wrapper .left-side-container {
    margin: 10px 5px 5px 10px;
}
.projects-wrapper .right-side-wrapper .right-side-container {
    margin: 10px 10px 5px 5px;
}
}		