.skillr-app-wrapper {
	background-color: #374761;
	background-image: url("../../../images/projects/skillr/skillrs.png"), url("../../../images/projects/skillr/session.png"), url("../../../images/projects/skillr/skill.png");
	height: 800px;
	background-position: center 250px, left 250px, right 250px;
	background-repeat: no-repeat;
  	background-size: contain;
	background-origin: content-box;
	text-align: center;
	padding: 65px 50px;
	color: #fff;
	margin-bottom: 10px;
}

@media (min-width: 768px) {
	.featured-app-wrapper {
		padding: 65px 100px;
	}
}

@media (min-width: 992px) {
	.featured-app-wrapper {
		padding: 65px 200px;
	}
}